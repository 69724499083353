import Cookies from 'js-cookie';
export const DOMAIN_NAME="thepls.in";
// export const DOMAIN_NAME="localhost";
export const ENDPOINT="http://localhost:5000";
export const TINYMCE_KEY='11k34hvlepkvwky2hvrl5lv0kp87vlu3dfsvudu4n18ulex9';
export const TINYMCE_PLUGINS="anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code";
export const TINYMCE_TOOLBAR="insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image jbimages media | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | forecolor backcolor | fullscreen";

export const get_cookies_data=()=>{
    // Cookies.set('userId', '896702')
    return Cookies.get()
}

export const TIMER_MILISECONDS=10000;
export const set_cookies_login_data=(user_data,community_details)=>{
    if(user_data){
        // console.log(user_data);
        let expires=7;
        let domain=DOMAIN_NAME;
        // let domain='localhost';
        // console.log(domain);
        let path="/"
        Cookies.set('userId', user_data.userId??null,{ expires: expires ,domain,path})
        Cookies.set('userEmail', user_data.email??null,{ expires: expires ,domain,path})
        Cookies.set('userFirstName', user_data.first_name??null,{ expires: expires ,domain,path})
        Cookies.set('userLastName', user_data.last_name??null,{ expires: expires ,domain,path})
        Cookies.set('userImage', user_data.profile_image??null,{ expires: expires ,domain,path})
        Cookies.set('userCoverImage', user_data.cover_image??null,{ expires: expires ,domain,path})        
        Cookies.set('user_type', user_data.user_type??null,{ expires: expires ,domain,path})
        Cookies.set('domainId', user_data.domain_id??null,{ expires: expires ,domain,path})
        Cookies.set('domainName', user_data.domain_name??null,{ expires: expires ,domain,path})
        Cookies.set('examId', user_data.exam_id??null,{ expires: expires ,domain,path})
        Cookies.set('examTitle', user_data.exam_title??null,{ expires: expires ,domain,path})
        Cookies.set('examSlug', user_data.exam_slug??null,{ expires: expires ,domain,path})
        Cookies.set('userWork', user_data.work??null,{ expires: expires ,domain,path})
        Cookies.set('userWork_at', user_data.work_at??null,{ expires: expires ,domain,path})
        Cookies.set('userAbout', user_data.about??null,{ expires: expires ,domain,path})
        Cookies.set('userState', user_data.state??null,{ expires: expires ,domain,path})
        Cookies.set('userStateId', user_data.state_id??null,{ expires: expires ,domain,path})
        Cookies.set('userCity', user_data.city??null,{ expires: expires ,domain,path})
        Cookies.set('userCityId', user_data.city_id??null,{ expires: expires ,domain,path})
        Cookies.set('userPhone', user_data.phone??null,{ expires: expires ,domain,path})
        Cookies.set('free_trial_days', user_data.free_trial_days??null,{ expires: expires ,domain,path})
        Cookies.set('free_trial_last_date', user_data.free_trial_last_date??null,{ expires: expires ,domain,path})       
        Cookies.set('logged_in', true,{ expires: expires ,domain,path})
        
        if(community_details){
            Cookies.set('com_userId', community_details._id,{ expires: expires ,domain,path})
        }
    }else{
        return false;
    }
}   

export const delete_cookies_data=()=>{
    let domain=DOMAIN_NAME;
    // let domain='localhost';
    // console.log(domain);
    let path="/"   
    Cookies.remove('userId', {domain,path})
    Cookies.remove('userEmail', { domain,path  })
    Cookies.remove('userFirstName', { domain,path  })
    Cookies.remove('userLastName', { domain,path  })
    Cookies.remove('userImage', { domain,path})
    Cookies.remove('userCoverImage', { domain,path})
    Cookies.remove('user_type', { domain,path})
    Cookies.remove('domainId', { domain,path})
    Cookies.remove('domainName', { domain,path})
    Cookies.remove('examId', { domain,path })
    Cookies.remove('examTitle', { domain,path })
    Cookies.remove('examSlug', { domain,path })
    Cookies.remove('logged_in', { domain,path })
    Cookies.remove('com_userId', { domain,path })
    Cookies.remove('userWork', { domain,path })
    Cookies.remove('userWork_at', { domain,path })
    Cookies.remove('userAbout', { domain,path })
    Cookies.remove('userState', { domain,path })
    Cookies.remove('userStateId', { domain,path })
    Cookies.remove('userCity', { domain,path })
    Cookies.remove('userCityId', { domain,path })
    Cookies.remove('userPhone', { domain,path })
    Cookies.remove('free_trial_days', { domain,path })
    Cookies.remove('free_trial_last_date', { domain,path })
    return true;
}

export const isFollowing=(followings,currentUserId)=>{
    if(followings&&currentUserId){
        const following=followings.filter((following)=> following._id===currentUserId);
        if(following.length>0){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export const isLikedPost=(likes,currentUserId)=>{
   
    if(likes&&currentUserId){
        const like=likes.filter((like)=> like._id===currentUserId);
        if(like.length>0){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

export const isLikedThread=(likes,currentUserId)=>{
   
    if(likes&&currentUserId){
        const like=likes.filter((like)=> like._id===currentUserId);
        if(like.length>0){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}
