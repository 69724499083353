/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  Box as RescourceIcon,
  BookOpen as BookOpenIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';


const sections = [
  // {
  //   items: [
  //     {
  //       title: 'Dashboard',
  //       icon: PieChartIcon,
  //       href: `/`
  //     },
  //   ]
  // },
  // {
  //   subheader: 'Community',
  //   items: [
  //     {
  //       title: 'Community Platform',
  //       href: '/app/community',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Browse Communities',
  //           href: '/app/community'
  //         },
  //       ]
  //     },
  //   ]
  // },

  {
    subheader: 'Offerings',
    items: [
      // {
      //   title: 'Study Planner',
      //   href: 'https://gsscore.com/Myaccount/page_planner',
      //   icon: BookOpenIcon,
      //   type:'offering',
      // },
      {
        title: <span>ABL (<span style={{fontSize:'9px'}}>Assessment Based Learning</span>)</span>,
        href: 'https://thepls.in/Myaccount_free/ablLandingPage',
        icon: BookOpenIcon,
        type:'offering',
      },
      {
        title: "Study Scheduler",
        href: 'https://thepls.in/Myaccount_free/studyplannerLandingPage',
        icon: CalendarIcon,
        type:'offering',
      },
      // {
      //   title: 'IAS PRELIMS 2024',
      //   href: 'https://gsscore.com/60-days-planner',
      //   icon: RescourceIcon,
      //   type:'offering',
      // },
      // {
      //   title: 'Resources',
      //   href: 'https://gsscore.com/Myaccount/aboutResourceLandingPage',
      //   icon: RescourceIcon,
      //   type:'offering',
      // },
      // {
      //   title: 'Modulearn',
      //   href: 'https://gsscore.com/Myaccount/courseLandingPage',
      //   icon: EditIcon,
      //   type:'offering',
      // }
    ]
  },

  {
    subheader: 'Social',
    items: [
      {
        title: 'Threads',
        href: '/app/threads',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Browse Threads',
            href: '/app/threads/browse'
          },
          {
            title: 'Create Threads',
            href: '/app/threads/create',
            loginRequired: true,
          },
        ]
      },      
      {
        title: 'Create Acitivity',
        icon: ShareIcon,
        href: `/app/acitivity/create`
      },
      // {
      //   title: 'Mentor Hub',
      //   icon: UsersIcon,
      //   href: `/app/mentor-hub`
      // },
      {
        // title: 'Mentor Hub',
        icon: UserIcon,
        // href: `/app/mentor-hub`
        title: 'Profile',
        href: '/app/social/profile',
      },
      // {
      //   title: 'Social Platform',
      //   href: '/app/social',
      //   icon: ShareIcon,
      //   items: [
      //     {
      //       title: 'Profile',
      //       href: '/app/social/profile',
      //     },
      //     {
      //       title: 'Feed',
      //       href: '/app/social/feed'
      //     }
      //   ]
      // },
    ]
  },

];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  // console.log(pathname);
  // console.log(items);
  return (
    <List disablePadding
    
    >
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        type={item.type}
        key={key}
        title={item.title}
        className='eachItem'
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  subHeader:{
    background: "#4354dd",
    color: "#fff",
    fontSize: "16px",
    paddingLeft: "10px",
    borderRadius: "0px !important",
    lineHeight: "35px",
    fontWeight: "bold",
}
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const {authData}=useSelector((state)=>state.authData);
  const {exam_slug}=useParams();
  // console.log("NAVBAR");
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        {/* <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to={authData && authData.userId?`{/${exam_slug}/app/account`:"#"}>
              <Avatar
                alt={authData.userFirstName}
                className={classes.avatar}
                src={authData.userImage??''}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to={`/${exam_slug}/app/account`}
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {authData.userFirstName}
            </Link>
          </Box>
        </Box> */}
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                  className={classes.subHeader}
                >
                  {section.subheader}
                </ListSubheader>
              )}
              // className='eachLink'
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/"
            >
              Check our study resource
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
